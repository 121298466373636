import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ApiUser } from '../../common/api/api-interfaces';
import { UserRolePreferences } from '../../common/api/zod-schemas';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { DataTypes, Domains } from '../../common/constants/constants';
import { dateManagerService } from '../../common/date-manager/date-manager-service';
import { rootStore } from '../../common/store/root-store';
import { VersionId } from '../../common/v2/api/types';
import { useLatestVersionsContext } from '../../common/v2/context/contexts';

const preLoadDashboards = async () => {
  if (!rootStore.dashboardStore.dashboards.length) {
    return rootStore.dashboardStore.getAllDashboards();
  }
};
const preLoadNonNullFields = async () => {
  return rootStore.employeeDataStore.loadNonNullFields();
};

const preLoadAliases = async (domain: Domains) => {
  if (!rootStore.aliasStore.aliasesLoaded) {
    return rootStore.aliasStore.loadAliases(domain);
  }
};

const loadOldestStartDate = (latestVersionId?: VersionId) => {
  if (!rootStore.employeeDataStore.oldestStartDate) {
    rootStore.employeeDataStore.loadOldestStartDate(latestVersionId);
  }
};

const getSelectedCompany = () => {
  if (!rootStore.companyStore.selectedCompanyFetched) {
    rootStore.companyStore.getSelectedCompany();
  }
};

const loadUserRolePreference = async () => {
  if (!rootStore.userStore.userRolePreferencesFetched) {
    return rootStore.userStore.loadUserRolePreference();
  }
  return null;
};

const loadUserInfo = async () => {
  if (!rootStore.userStore.currentUserFetched) {
    return rootStore.userStore.loadUserInfo();
  }
  return null;
};

const loadWindowIntercomSettings = (userRolePreferences: UserRolePreferences | null, userInfo: ApiUser | null) => {
  rootStore.intercomStore.applyWindowIntercomSettings(userRolePreferences, userInfo?.email ?? null);
};

const loadBlockingDependencies = async (latestVersionId?: VersionId) => {
  rootStore.metricStore.loadAllMetricGroupDetails();
  const [userRolePreferences, currentUser] = await Promise.all([loadUserRolePreference(), loadUserInfo()]);
  loadWindowIntercomSettings(userRolePreferences, currentUser);
  await Promise.allSettled([preLoadDashboards(), loadOldestStartDate(latestVersionId), getSelectedCompany()]);
};

const preLoadNonBlockingDependencies = async () => {
  await rootStore.timeSliderStore.setUpTimeSlider();
  preLoadNonNullFields();
  preLoadAliases(rootStore.companyStore.domain as Domains);
};

export const areBlockingDependenciesReady = () => {
  const { selectedCompanyFetched } = rootStore.companyStore;
  const { userRolePreferencesFetched } = rootStore.userStore;
  const { dashboardsFetched } = rootStore.dashboardStore;
  const oldestStartDateFetched =
    rootStore.employeeDataStore.oldestStartDate || rootStore.employeeDataStore.oldestStartDateNotFound;
  const { executorRoleReady } = rootStore.permissionsStore;
  const { dateFormatManagerReady } = dateManagerService;
  const allMetricGroupDetailsLoaded = rootStore.metricStore.allMetricGroupDetailsLoaded;
  const blockingDependenciesReady =
    selectedCompanyFetched &&
    userRolePreferencesFetched &&
    dashboardsFetched &&
    executorRoleReady() &&
    oldestStartDateFetched &&
    dateFormatManagerReady &&
    allMetricGroupDetailsLoaded;
  return blockingDependenciesReady;
};

export const DependenciesGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const latestVersions = useLatestVersionsContext();
  const { executorRoleReady } = rootStore.permissionsStore;
  useEffect(() => {
    if (executorRoleReady()) {
      loadBlockingDependencies(latestVersions[DataTypes.EMPLOYEE]);
    }
  }, [executorRoleReady()]);
  const { selectedLanguage } = rootStore.languageStore;
  const firstMonthOfYear = rootStore.timeSliderStore.timeSliderConfig.firstMonthOfYear;
  useEffect(() => {
    dateManagerService.createDateFormatManagers(selectedLanguage.id, firstMonthOfYear);
  }, [selectedLanguage, firstMonthOfYear]);
  const blockingDependenciesReady = areBlockingDependenciesReady();
  useEffect(() => {
    if (blockingDependenciesReady) {
      preLoadNonBlockingDependencies();
    }
  }, [blockingDependenciesReady]);
  if (!blockingDependenciesReady) {
    return <LoadingOverlay />;
  } else {
    return <>{children}</>;
  }
});
