import { Domains, DOMAIN_KEY } from '../../constants';

interface CompanyService {
  getDomains(): Promise<Domains[]>;
  getDomain(): Domains | null;
}

export class BackendCompanyService implements CompanyService {
  constructor(readonly availableDomains: Domains[]) {}

  public getDomains = async (): Promise<Domains[]> => {
    return this.availableDomains;
  };

  public getDomain = (): Domains | null => null;
}

export class LocalStorageCompanyService implements CompanyService {
  constructor(readonly availableDomains: Domains[]) {}

  public getDomain = (): Domains | null => {
    const stored: string | null = localStorage.getItem(DOMAIN_KEY);
    return this.availableDomains.find((d) => d === stored) ?? null;
  };

  public getDomains = () => Promise.resolve(this.availableDomains);
}
